<template>
  <div class="content-wrapper ">
    <h1>Settings Manager</h1>

    <b-card class="w-100">
      <b-row>
        <b-col cols="12" class="mb-4">
          <h4>Logo</h4>

          <div
            class="upload-photo-cicrle"
            v-bind:style="{
              backgroundImage: 'url(' + backgroundLogo() + ')',
            }"
          ></div>
          <avatar-cropper
            :trigger="changeLogoTrigger"
            :upload-handler="handleLogoUpload"
            :labels="{
              submit: 'Upload',
              cancel: 'Cancel',
              main: 'Change Logo',
            }"
            class="mb-3"
          />
        </b-col>

        <b-col cols="12" class="mb-4">
          <h4>Login Background</h4>

          <div
            class="upload-photo-rectangle"
            v-bind:style="{
              backgroundImage: 'url(' + backgroundBackground() + ')',
            }"
            style="max-height: 200px;"
          ></div>

          <avatar-cropper
            :trigger="changeBackgroundTrigger"
            :upload-handler="handleBackgroundUpload"
            :labels="{
              submit: 'Upload',
              cancel: 'Cancel',
              main: 'Change Background',
            }"
            :cropperOptions="{
              autoCropArea: 1,
              viewMode: 1,
              movable: false,
              zoomable: false,
            }"
            class="mb-3"
          />
        </b-col>

        <b-col cols="12" md="3" class="mb-4">
          <h4>Colors</h4>
          <b-form-group label="Primary color" label-for="primary_color">
            <b-form-input
              id="primary_color"
              v-model="settings.Primary_Color_App"
              type="color"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-4">
          <h4>-</h4>
          <b-form-group label="Secondary color" label-for="secondary_color">
            <b-form-input
              id="secondary_color"
              v-model="settings.Secondary_Color_App"
              type="color"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mb-4">
          <b-button
            squared
            size="lg"
            class="big-button"
            @click="saveSettings()"
            :disabled="loading.saveData"
          >
            {{ loading.saveData ? "Saving..." : "Save" }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService";
import AvatarCropper from "@/components/AvatarCropper.vue";
export default {
  name: "SettingsManager",
  components: {
    AvatarCropper,
  },
  data() {
    return {
      loading: {
        getData: false,
        saveData: false,
      },

      settings: {
        url_logo: "",
      },

      changeLogoTrigger: false,
      file_logo_url: "",

      changeBackgroundTrigger: false,
      file_background_url: "",
    };
  },
  computed: {
    settingsFromStore() {
      return this.$store.state.config.config;
    },
  },
  mounted() {
    this.settings = JSON.parse(JSON.stringify(this.settingsFromStore));
  },
  methods: {
    saveSettings() {
      this.loading.saveData = true;

      const service = new AxiosService("Setting");

      service
        .putCustomEndpoint("Setting", "", this.settings)
        .then((res) => {
          if (res) {
            this.$successToast();
          }
        })
        .catch((err) => {
          console.log("FILE NOT UPLOADED");
          console.log(err);
        })
        .finally(() => {
          this.loading.saveData = false;
        });
    },
    backgroundBackground() {
      return this.file_background_url || this.settings.url_background;
    },
    backgroundLogo() {
      return this.file_logo_url || this.settings.url_logo;
    },
    handleLogoUpload(cropper) {
      const service = new AxiosService("Upload");

      cropper
        .getCroppedCanvas({
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
          maxWidth: 1000,
          maxHeight: 1000,
        })
        .toBlob(
          async (blob) => {
            const formData = new FormData();

            formData.append("file", blob, "image.jpg");

            service
              .uploadFile(formData)
              .then((res) => {
                if (res) {
                  this.settings.url_logo = this.$driveAddress + res.data;
                }
              })
              .catch((err) => {
                console.log("FILE NOT UPLOADED");
                console.log(err);
              });
          },
          this.outputMime || this.mimeType,
          this.outputQuality
        );
    },

    handleBackgroundUpload(cropper) {
      const service = new AxiosService("Upload");

      cropper
        .getCroppedCanvas({
          aspectRatio: 16 / 9,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
          maxWidth: 1000,
          maxHeight: 1000,
        })
        .toBlob(
          async (blob) => {
            const formData = new FormData();

            formData.append("file", blob, "image.jpg");

            service
              .uploadFile(formData)
              .then((res) => {
                if (res) {
                  this.settings.url_background = this.$driveAddress + res.data;
                }
              })
              .catch((err) => {
                console.log("FILE NOT UPLOADED");
                console.log(err);
              });
          },
          this.outputMime || this.mimeType,
          this.outputQuality
        );
    },
  },
};
</script>
