var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper "},[_c('h1',[_vm._v("Settings Manager")]),_c('b-card',{staticClass:"w-100"},[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Logo")]),_c('div',{staticClass:"upload-photo-cicrle",style:({
            backgroundImage: 'url(' + _vm.backgroundLogo() + ')',
          })}),_c('avatar-cropper',{staticClass:"mb-3",attrs:{"trigger":_vm.changeLogoTrigger,"upload-handler":_vm.handleLogoUpload,"labels":{
            submit: 'Upload',
            cancel: 'Cancel',
            main: 'Change Logo',
          }}})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Login Background")]),_c('div',{staticClass:"upload-photo-rectangle",staticStyle:{"max-height":"200px"},style:({
            backgroundImage: 'url(' + _vm.backgroundBackground() + ')',
          })}),_c('avatar-cropper',{staticClass:"mb-3",attrs:{"trigger":_vm.changeBackgroundTrigger,"upload-handler":_vm.handleBackgroundUpload,"labels":{
            submit: 'Upload',
            cancel: 'Cancel',
            main: 'Change Background',
          },"cropperOptions":{
            autoCropArea: 1,
            viewMode: 1,
            movable: false,
            zoomable: false,
          }}})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"3"}},[_c('h4',[_vm._v("Colors")]),_c('b-form-group',{attrs:{"label":"Primary color","label-for":"primary_color"}},[_c('b-form-input',{attrs:{"id":"primary_color","type":"color"},model:{value:(_vm.settings.Primary_Color_App),callback:function ($$v) {_vm.$set(_vm.settings, "Primary_Color_App", $$v)},expression:"settings.Primary_Color_App"}})],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"3"}},[_c('h4',[_vm._v("-")]),_c('b-form-group',{attrs:{"label":"Secondary color","label-for":"secondary_color"}},[_c('b-form-input',{attrs:{"id":"secondary_color","type":"color"},model:{value:(_vm.settings.Secondary_Color_App),callback:function ($$v) {_vm.$set(_vm.settings, "Secondary_Color_App", $$v)},expression:"settings.Secondary_Color_App"}})],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"big-button",attrs:{"squared":"","size":"lg","disabled":_vm.loading.saveData},on:{"click":function($event){return _vm.saveSettings()}}},[_vm._v(" "+_vm._s(_vm.loading.saveData ? "Saving..." : "Save")+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }